.favorite-item {
    background-color: white;
    padding: 10px 30px;
    margin-bottom: 15px;
}

.favorite-item h2 {
    font-size: 20px;
    margin: 0;
    margin-top: 10px;
}

.favorite-item p {
    font-size: 18px;
    margin: 10px 0;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actions button {
    background-color: transparent;
    border: 1px solid #c72c41;
    outline: none;
    color: #c72c41;
    padding: 9px 20px;
    cursor: pointer;
    border-radius: 7px;
    font-size: 16px;
    transition: all 250ms linear;
}

.actions button:hover,
.actions button:active {
    background-color: #c72c41;
    color: white;
}