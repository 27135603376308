.form {
    width: 100%;
    text-align: center;
}

.form h2 {
    margin: 0;
    margin-bottom: 25px;
    color: #121212;
}

.control {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.control label {
    color: #131313;
    margin-right: 10px;
    min-width: 130px;
    text-align: left;
}

.control input {
    border: none;
    outline: none;
    background-color: #2a9c95;
    color: white;
    border-radius: 20px;
    width: 250px;
    height: 30px;   
    box-shadow: 0 1px 3.5px rgba(0, 0, 0, 0.25);
    transition: all 250ms linear;
}


.control input:focus::placeholder{
    transform: translateY(-20px);
    z-index: 2;
}

.control input:focus {
    box-shadow: none;
}

.control.invalid input {
    background-color: #c07676;
}

.actions button {
    border: none;
    outline: none;
    background-color: #ee4540;
    border-radius: 7px;
    padding: 9px 20px;
    font-size: 17.5px;
    color: white;
    cursor: pointer;
    margin: 20px 0;
}

.actions.invalid button {
    background-color: lightgray;
    color: #121212;
    cursor: not-allowed;
}

.switch {
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.stronglity {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 160px;
}

.stronglity-open {
    animation: open 700ms forwards;
}

.stronglity-exit {
    animation: close 700ms forwards;
}

.stronglity p {
    margin: 0;
    margin-right: 15px;
}

.chart {
    border: 1px solid #2a9790;
    width: 100px;
    height: 15px;
    border-radius: 25px;
    overflow: hidden;
    transition: all 350ms linear;
}

.error {
    color: red;
    font-size: 12px;
    transition: all 300ms ease-out;
}

.weak p {
    color: red;
    transition: all 250ms linear;
}

.weak .chart div {
    width: 25% !important;
    background-color: red;
}

.medium p {
    color: orange;
    transition: all 250ms linear;
}

.medium .chart div {
    width: 50% !important;
    background-color: orange;
}

.good p {
    color: lightgreen;
    transition: all 250ms linear;
}

.good .chart div {
    width: 75% !important;
    background-color: lightgreen;
}

.strong p {
    color: green;
    transition: all 250ms linear;
}

.strong .chart div {
    width: 100% !important;
    background-color: green;
}

.chart div {
    background-color: red;
    width: 0%;
    height: 100%;
    border-radius: 0 50px 50px 0;
    transition: all 250ms linear;
}

.error-open {
    animation: open 550ms forwards;
}
.error-apear {
    animation: close 550ms forwards;
}

@keyframes open {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes close {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}