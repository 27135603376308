.favorite {
    padding: 0 50px;
    margin: 0 auto;
    margin-top: 25px;
}

.not-found {
    color: orange;
    font-size: 30px;
    display: block;
    text-align: center;
}

.actions {
    text-align: center;
}

.actions button {
    background-color: transparent;
    border: none;
    outline: none;
    text-align: center;
}

.actions button a {
    text-decoration: none;
    background-color: #c72c41;
    color: white;
    padding: 9px 20px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 350ms linear;
    font-size: 18px;
}

.actions button a:hover,
.actions button a:active {
    background-color: #931324;
}