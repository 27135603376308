.item h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    margin-top: 5px;
}

.item p {
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0;

}
