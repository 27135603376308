.product-list {
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: white;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actions button {
    outline: none;
    padding: 9px 18px;
    border-radius: 7px;
    border: 1px solid #c72c41;
    background-color: transparent;
    color: #c72c41;
    cursor: pointer;
    font-size: 18px;
    transition: all 250ms linear;
}

.actions button:hover,
.actions button:active {
    background-color: #c75c6a;
    color: white;
}

.actions button.active {
    background-color: #c72c41;
    color: white;
}