.header {
    height: 70px;
    background-color: #ee4540;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header a img {
    width: 100px;
    margin-right: 50px;
}

.header a {
    color: white;
    text-decoration: none;
    margin-left: 25px;
    font-size: 25px;
    transition: all 250ms ease-out;
}

.header a:hover,
.header a:active,
.header a.active {
    color: #2d132c;
}

.header button {
    border: 1px solid white;
    color: white;
    background-color: transparent;
    outline: none;
    border-radius: 7px;
    padding: 9px 20px;
    font-size: 17px;
    margin-left: 10px;
    cursor: pointer;
}