@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #e0e0e0;
}


.MainHeader_header__2bYCw {
    height: 70px;
    background-color: #ee4540;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainHeader_header__2bYCw a img {
    width: 100px;
    margin-right: 50px;
}

.MainHeader_header__2bYCw a {
    color: white;
    text-decoration: none;
    margin-left: 25px;
    font-size: 25px;
    transition: all 250ms ease-out;
}

.MainHeader_header__2bYCw a:hover,
.MainHeader_header__2bYCw a:active,
.MainHeader_header__2bYCw a.MainHeader_active__3YPfZ {
    color: #2d132c;
}

.MainHeader_header__2bYCw button {
    border: 1px solid white;
    color: white;
    background-color: transparent;
    outline: none;
    border-radius: 7px;
    padding: 9px 20px;
    font-size: 17px;
    margin-left: 10px;
    cursor: pointer;
}
.Card_card__2TYDV {
    border-radius: 10px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}
.ProductsItem_item__2vj1x h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    margin-top: 5px;
}

.ProductsItem_item__2vj1x p {
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0;

}

.ProductsList_product-list__1dte9 {
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: white;
}

.ProductsList_actions__1aG78 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ProductsList_actions__1aG78 button {
    outline: none;
    padding: 9px 18px;
    border-radius: 7px;
    border: 1px solid #c72c41;
    background-color: transparent;
    color: #c72c41;
    cursor: pointer;
    font-size: 18px;
    transition: all 250ms linear;
}

.ProductsList_actions__1aG78 button:hover,
.ProductsList_actions__1aG78 button:active {
    background-color: #c75c6a;
    color: white;
}

.ProductsList_actions__1aG78 button.ProductsList_active__1TKNo {
    background-color: #c72c41;
    color: white;
}
.Products_products__1TsDe {
    min-height: 150px;
    padding: 0 50px;
    padding-top: 25px;
}
.FavoriteList_favorite-item__1wtY1 {
    background-color: white;
    padding: 10px 30px;
    margin-bottom: 15px;
}

.FavoriteList_favorite-item__1wtY1 h2 {
    font-size: 20px;
    margin: 0;
    margin-top: 10px;
}

.FavoriteList_favorite-item__1wtY1 p {
    font-size: 18px;
    margin: 10px 0;
}

.FavoriteList_actions__1zksH {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.FavoriteList_actions__1zksH button {
    background-color: transparent;
    border: 1px solid #c72c41;
    outline: none;
    color: #c72c41;
    padding: 9px 20px;
    cursor: pointer;
    border-radius: 7px;
    font-size: 16px;
    transition: all 250ms linear;
}

.FavoriteList_actions__1zksH button:hover,
.FavoriteList_actions__1zksH button:active {
    background-color: #c72c41;
    color: white;
}
.Favorite_favorite__2JpJm {
    padding: 0 50px;
    margin: 0 auto;
    margin-top: 25px;
}

.Favorite_not-found__3BaKg {
    color: orange;
    font-size: 30px;
    display: block;
    text-align: center;
}

.Favorite_actions__2Q5rH {
    text-align: center;
}

.Favorite_actions__2Q5rH button {
    background-color: transparent;
    border: none;
    outline: none;
    text-align: center;
}

.Favorite_actions__2Q5rH button a {
    text-decoration: none;
    background-color: #c72c41;
    color: white;
    padding: 9px 20px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 350ms linear;
    font-size: 18px;
}

.Favorite_actions__2Q5rH button a:hover,
.Favorite_actions__2Q5rH button a:active {
    background-color: #931324;
}
.AuthForm_form__LeGnC {
    width: 100%;
    text-align: center;
}

.AuthForm_form__LeGnC h2 {
    margin: 0;
    margin-bottom: 25px;
    color: #121212;
}

.AuthForm_control__df4BR {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.AuthForm_control__df4BR label {
    color: #131313;
    margin-right: 10px;
    min-width: 130px;
    text-align: left;
}

.AuthForm_control__df4BR input {
    border: none;
    outline: none;
    background-color: #2a9c95;
    color: white;
    border-radius: 20px;
    width: 250px;
    height: 30px;   
    box-shadow: 0 1px 3.5px rgba(0, 0, 0, 0.25);
    transition: all 250ms linear;
}


.AuthForm_control__df4BR input:focus::-webkit-input-placeholder{
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    z-index: 2;
}


.AuthForm_control__df4BR input:focus::placeholder{
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    z-index: 2;
}

.AuthForm_control__df4BR input:focus {
    box-shadow: none;
}

.AuthForm_control__df4BR.AuthForm_invalid__r5-ms input {
    background-color: #c07676;
}

.AuthForm_actions__3EsgB button {
    border: none;
    outline: none;
    background-color: #ee4540;
    border-radius: 7px;
    padding: 9px 20px;
    font-size: 17.5px;
    color: white;
    cursor: pointer;
    margin: 20px 0;
}

.AuthForm_actions__3EsgB.AuthForm_invalid__r5-ms button {
    background-color: lightgray;
    color: #121212;
    cursor: not-allowed;
}

.AuthForm_switch__3ZuBK {
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.AuthForm_stronglity__3Td7s {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 160px;
}

.AuthForm_stronglity-open__2dkqc {
    -webkit-animation: AuthForm_open__5hM1A 700ms forwards;
            animation: AuthForm_open__5hM1A 700ms forwards;
}

.AuthForm_stronglity-exit__3Df6F {
    -webkit-animation: AuthForm_close__35VXd 700ms forwards;
            animation: AuthForm_close__35VXd 700ms forwards;
}

.AuthForm_stronglity__3Td7s p {
    margin: 0;
    margin-right: 15px;
}

.AuthForm_chart__3T43l {
    border: 1px solid #2a9790;
    width: 100px;
    height: 15px;
    border-radius: 25px;
    overflow: hidden;
    transition: all 350ms linear;
}

.AuthForm_error__1rFEI {
    color: red;
    font-size: 12px;
    transition: all 300ms ease-out;
}

.AuthForm_weak__6oJcj p {
    color: red;
    transition: all 250ms linear;
}

.AuthForm_weak__6oJcj .AuthForm_chart__3T43l div {
    width: 25% !important;
    background-color: red;
}

.AuthForm_medium__2qeh- p {
    color: orange;
    transition: all 250ms linear;
}

.AuthForm_medium__2qeh- .AuthForm_chart__3T43l div {
    width: 50% !important;
    background-color: orange;
}

.AuthForm_good__2h5Ft p {
    color: lightgreen;
    transition: all 250ms linear;
}

.AuthForm_good__2h5Ft .AuthForm_chart__3T43l div {
    width: 75% !important;
    background-color: lightgreen;
}

.AuthForm_strong__8qV7m p {
    color: green;
    transition: all 250ms linear;
}

.AuthForm_strong__8qV7m .AuthForm_chart__3T43l div {
    width: 100% !important;
    background-color: green;
}

.AuthForm_chart__3T43l div {
    background-color: red;
    width: 0%;
    height: 100%;
    border-radius: 0 50px 50px 0;
    transition: all 250ms linear;
}

.AuthForm_error-open__TqCw7 {
    -webkit-animation: AuthForm_open__5hM1A 550ms forwards;
            animation: AuthForm_open__5hM1A 550ms forwards;
}
.AuthForm_error-apear__1DQpd {
    -webkit-animation: AuthForm_close__35VXd 550ms forwards;
            animation: AuthForm_close__35VXd 550ms forwards;
}

@-webkit-keyframes AuthForm_open__5hM1A {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes AuthForm_open__5hM1A {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes AuthForm_close__35VXd {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes AuthForm_close__35VXd {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}
.Auth_auth__IyjzY {
    width: 500px;
    background-color: #3bbbb3;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 20px;
}
